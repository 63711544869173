import type { ILoader } from '../../../global/type'

const defaultClasses = 'loading loading-ring '

const loaderSize = {
  lg: defaultClasses + 'loading-lg',
  md: defaultClasses + 'loading-md',
  sm: defaultClasses + 'loading-sm',
  xs: defaultClasses + 'loading-xs',
}

const Loader = ({ size = 'md' }: ILoader) => {
  return <span className={loaderSize[size]}></span>
}

export default Loader
