import { useAtom } from 'jotai'
import { useEffect } from 'react'

import * as atoms from '../stores/store'

/**
 * a custom hook to switch between theme
 * @default "dark"
 * @returns
 */

interface ThemeColor {
  [key: string]: string
  accent: string
  error: string
  info: string
  neutral: string
  primary: string
  secondary: string
  success: string
  warning: string
}

interface ThemesInterface {
  [selector: string]: ThemeColor
}

const themes: ThemesInterface = {
  '[data-theme=black]': {
    '--animation-btn': '0',
    '--animation-input': '0',
    '--btn-focus-scale': '1',
    '--btn-text-case': 'lowercase',
    '--rounded-badge': '0',
    '--rounded-box': '0',
    '--rounded-btn': '0',
    '--tab-radius': '0',
    accent: '#343232',
    'base-100': '#000000',
    'base-200': '#0D0D0D',
    'base-300': '#1A1919',
    error: '#ff0000',
    info: '#0000ff',
    neutral: '#272626',
    'neutral-focus': '#343232',
    primary: '#343232',
    secondary: '#343232',
    success: '#008000',
    warning: '#ffff00',
  },

  '[data-theme=coffee]': {
    accent: '#10576D',
    'base-100': '#20161F',
    'base-content': '#756E63',
    error: '#FC9581',
    info: '#8DCAC1',
    neutral: '#120C12',
    primary: '#DB924B',
    secondary: '#263E3F',
    success: '#9DB787',
    warning: '#FFD25F',
  },
  '[data-theme=cyberpunk]': {
    '--rounded-badge': '0',
    '--rounded-box': '0',
    '--rounded-btn': '0',
    '--tab-radius': '0',
    accent: '#c07eec',
    'base-100': '#ffee00',
    neutral: '#423f00',
    'neutral-content': '#ffee00',
    primary: '#ff7598',
    secondary: '#75d1f0',
  },
  '[data-theme=lofi]': {
    '--animation-btn': '0',
    '--animation-input': '0',
    '--btn-focus-scale': '1',
    '--rounded-badge': '0.125rem',
    '--rounded-box': '0.25rem',
    '--rounded-btn': '0.125rem',
    '--tab-radius': '0',
    accent: '#262626',
    'accent-content': '#ffffff',
    'base-100': '#ffffff',
    'base-200': '#F2F2F2',
    'base-300': '#E6E5E5',
    'base-content': '#000000',
    error: '#DE1C8D',
    'error-content': '#ffffff',
    info: '#0070F3',
    'info-content': '#ffffff',
    neutral: '#000000',
    'neutral-content': '#ffffff',
    primary: '#0D0D0D',
    'primary-content': '#ffffff',
    secondary: '#1A1919',
    'secondary-content': '#ffffff',
    success: '#21CC51',
    'success-content': '#ffffff',
    warning: '#FF6154',
    'warning-content': '#ffffff',
  },
  '[data-theme=retro]': {
    '--rounded-badge': '0.4rem',
    '--rounded-box': '0.4rem',
    '--rounded-btn': '0.4rem',
    accent: '#ebdc99',
    'accent-content': '#282425',
    'base-100': '#e4d8b4',
    'base-200': '#d2c59d',
    'base-300': '#c6b386',
    'base-content': '#282425',
    error: '#dc2626',
    info: '#2563eb',
    neutral: '#7d7259',
    'neutral-content': '#e4d8b4',
    primary: '#ef9995',
    'primary-content': '#282425',
    secondary: '#a4cbb4',
    'secondary-content': '#282425',
    success: '#16a34a',
    warning: '#d97706',
  },
}

const useTheme = () => {
  const [themeName, setTheme] = useAtom(atoms.themeAtom)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.documentElement.setAttribute('data-theme', themeName)
    }
  }, [themeName])

  function themeProperty() {
    const key = '[data-theme=' + themeName + ']'
    return themes[key]
  }

  return {
    setTheme,
    themeName,
    themeProperty,
  }
}

export default useTheme
