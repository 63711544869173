import { ConnectKitButton } from 'connectkit'
import { useAccount } from 'wagmi'

import LogIn from '../../components/mollecules/LogIn/LogIn'
import useCookie from '../../hooks/useCookie'

export const Auth = ({ children }: any) => {
  const { getCookie, deleteCookie } = useCookie()
  const { isConnected } = useAccount()
  const token = getCookie('token')
  if (!isConnected) {
    deleteCookie('token')
    return (
      <div style={{ ...styles }}>
        <ConnectKitButton />
      </div>
    )
  } else if (!token) {
    return <LogIn />
  } else {
    return children
  }
}

const styles = {
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
}
