import { WorkflowUsage } from '../../../generated/ManagingApi'
import { FormInput } from '../../mollecules'

type IWorkflowForm = {
  name: string
  usage: WorkflowUsage
  handleUsageChange: (evt: any) => void
  handleOnSave: () => void
  handleOnReset: () => void
  isUpdated: boolean
  setName: (name: string) => void
}

const WorkflowForm = ({
  name,
  handleUsageChange,
  handleOnSave,
  isUpdated,
  handleOnReset,
  setName,
}: IWorkflowForm) => {
  return (
    <>
      <div className="flex">
        <div className="flex w-full">
          <FormInput label="Name" htmlFor="name" inline={true}>
            <input
              id="name"
              name="text"
              onChange={(evt) => setName(evt.target.value)}
              className="nodrag input"
              value={name}
            />
          </FormInput>
          <FormInput label="Usage" htmlFor="usage" inline={true}>
            <select
              className="select no-drag w-full max-w-xs"
              onChange={handleUsageChange}
            >
              {Object.keys(WorkflowUsage).map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </FormInput>
        </div>
        <div className="flex justify-end w-full">
          <button
            className="btn btn-primary"
            disabled={!isUpdated}
            onClick={handleOnSave}
          >
            Save
          </button>
          <button
            className="btn btn-primary"
            disabled={!isUpdated}
            onClick={handleOnReset}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  )
}

export default WorkflowForm
