import { StatusOfflineIcon } from '@heroicons/react/solid'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useAccount, useDisconnect, useSignMessage } from 'wagmi'

import useApiUrlStore from '../../../app/store/apiStore'
import { UserClient } from '../../../generated/ManagingApi'
import type { ILoginFormInput } from '../../../global/type'
import useCookie from '../../../hooks/useCookie'
import { SecondaryNavbar } from '../NavBar/NavBar'
import Toast from '../Toast/Toast'

const LogIn = () => {
  const { apiUrl } = useApiUrlStore()
  const { register, handleSubmit } = useForm<ILoginFormInput>()
  const { disconnect } = useDisconnect()
  const { address } = useAccount()
  const { signMessageAsync } = useSignMessage({})
  const { setCookie } = useCookie()

  const onSubmit: SubmitHandler<ILoginFormInput> = async (form) => {
    const message = 'wagmi'
    const signature = await signMessageAsync({ message })
    const t = new Toast('Creating token')

    if (signature && address) {
      const userClient = new UserClient({}, apiUrl)

      await userClient
        .user_CreateToken({
          address: address.toString(),
          message: message,
          name: form.name,
          signature: signature,
        })
        .then((data) => {
          setCookie('token', data, 1)
          location.reload()
        })
        .catch((err: any) => {
          t.update('error', 'Error :' + err.message)
        })
    }else{
      t.update('error', 'Error : No signature')
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <SecondaryNavbar />
      </div>

      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="md:h-screen lg:py-0 flex flex-col items-center justify-center px-6 py-8 mx-auto">
          <div className="dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 w-full bg-white rounded-lg shadow">
            <div className="md:space-y-6 sm:p-8 p-6 space-y-4">
              <h1
                className="md:text-2xl dark:text-white text-xl font-bold leading-tight tracking-tight text-gray-900"
                style={{ textAlign: 'center' }}
              >
                Login
              </h1>
              <form
                className="md:space-y-6 space-y-4"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <label
                    htmlFor="name"
                    className="dark:text-white block mb-2 text-sm font-medium text-gray-900"
                  >
                    Name
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('name')}
                  ></input>
                </div>
                <button
                  type="submit"
                  className="btn bg-primary w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Sign and login
                </button>
                <button
                  onClick={() => disconnect}
                  className="btn bg-primary w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Disconnect wallet{' '}
                  <StatusOfflineIcon width={20}></StatusOfflineIcon>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LogIn
