import React from 'react'

import type { IModalProps } from '../../../global/type'

const ModalHeader: React.FC<IModalProps> = ({ onClose, titleHeader }: any) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <button
        onClick={onClose}
        className="btn btn-sm btn-circle right-2 top-2 absolute"
      >
        ✕
      </button>
      <div className="text-primary mb-3 text-lg">{titleHeader}</div>
    </div>
  )
}

export default ModalHeader
