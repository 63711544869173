import { NavLink } from 'react-router-dom'

import type { INavItemProps } from '../../../global/interface'

function navLinkClasses(isActive: boolean, isMobile: boolean) {
  let commonClasses = 'block text-sm px-2 py-4'
  if (isMobile) {
    return `${commonClasses} ${
      isActive
        ? 'text-base-content bg-primary font-semibold'
        : 'hover:bg-primary transition duration-300'
    }`
  }
  commonClasses =
    'py-4 px-2 font-semibold hover:text-primary transition duration-300'
  return `${commonClasses} ${isActive ? 'text-primary' : 'text-base-content'}`
}

export default function NavItem({
  children,
  href,
  isMobile = false,
}: INavItemProps) {
  const item = (
    <NavLink
      to={href}
      className={({ isActive }) => navLinkClasses(isActive, isMobile)}
    >
      {children}
    </NavLink>
  )
  return isMobile ? <li>{item}</li> : item
}
