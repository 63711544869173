import React from 'react'

import { PieChart } from '../../../components/mollecules'
import type { Signal, Spotlight } from '../../../generated/ManagingApi'
import { TradeDirection } from '../../../generated/ManagingApi'
import useTheme from '../../../hooks/useTheme'

interface ISpotlightSummary {
  spotlights: Spotlight[] | undefined
}

interface ISummaryChart {
  fiveMinutes: ISummaryChartItem
  fifteenMinutes: ISummaryChartItem
  oneHour: ISummaryChartItem
  fourHours: ISummaryChartItem
  oneDay: ISummaryChartItem
}

interface ISummaryChartItem {
  long: number
  short: number
  none: number
}

const SpotlightSummary: React.FC<ISpotlightSummary> = ({ spotlights }) => {
  const { themeProperty } = useTheme()
  const theme = themeProperty()
  const labels = ['Long', 'Short', 'None']
  const colors = [theme.success, theme.error, 'grey']

  function GetSummaryChartItem(signal: Signal) {
    const summary: ISummaryChartItem = {
      long: 0,
      none: 0,
      short: 0,
    }
    if (signal) {
      switch (signal.direction) {
        case TradeDirection.Long:
          summary.long++
          break
        case TradeDirection.Short:
          summary.short++
          break
        case TradeDirection.None:
          summary.none++
          break
        default:
          summary.none++
          break
      }
    } else {
      summary.none++
    }
    return summary
  }

  function GetSignalChart(): React.ReactNode {
    const summary: ISummaryChart = {
      fifteenMinutes: {
        long: 0,
        none: 0,
        short: 0,
      },
      fiveMinutes: {
        long: 0,
        none: 0,
        short: 0,
      },
      fourHours: {
        long: 0,
        none: 0,
        short: 0,
      },
      oneDay: {
        long: 0,
        none: 0,
        short: 0,
      },
      oneHour: {
        long: 0,
        none: 0,
        short: 0,
      },
    }

    if (spotlights) {
      spotlights?.forEach((s) => {
        s.tickerSignals.forEach((t) => {
          const fiveMinutesSummary = GetSummaryChartItem(
            t.fiveMinutes[t.fiveMinutes.length - 1]
          )
          summary.fiveMinutes.long += fiveMinutesSummary.long
          summary.fiveMinutes.short += fiveMinutesSummary.short
          summary.fiveMinutes.none += fiveMinutesSummary.none
          const fifteenMinutesSummary = GetSummaryChartItem(
            t.fifteenMinutes[t.fifteenMinutes.length - 1]
          )
          summary.fifteenMinutes.long += fifteenMinutesSummary.long
          summary.fifteenMinutes.short += fifteenMinutesSummary.short
          summary.fifteenMinutes.none += fifteenMinutesSummary.none

          const oneHour = GetSummaryChartItem(t.oneHour[t.oneHour.length - 1])
          summary.oneHour.long += oneHour.long
          summary.oneHour.short += oneHour.short
          summary.oneHour.none += oneHour.none

          const fourHours = GetSummaryChartItem(
            t.fourHour[t.fourHour.length - 1]
          )
          summary.fourHours.long += fourHours.long
          summary.fourHours.short += fourHours.short
          summary.fourHours.none += fourHours.none

          const oneDay = GetSummaryChartItem(t.oneDay[t.oneDay.length - 1])
          summary.oneDay.long += oneDay.long
          summary.oneDay.short += oneDay.short
          summary.oneDay.none += oneDay.none
        })
      })
    }

    return (
      <>
        <div className="stats bg-primary text-primary-content mb-4">
          <div className="stat">
            <div className="stat-title">5min</div>
            <div className="stat-value">
              <PieChart
                data={[
                  summary.fiveMinutes.long,
                  summary.fiveMinutes.short,
                  summary.fiveMinutes.none,
                ]}
                labels={labels}
                colors={colors}
              ></PieChart>
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">15min</div>
            <div className="stat-value">
              <PieChart
                data={[
                  summary.fifteenMinutes.long,
                  summary.fifteenMinutes.short,
                  summary.fifteenMinutes.none,
                ]}
                labels={labels}
                colors={colors}
              ></PieChart>
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">1h</div>
            <div className="stat-value">
              <PieChart
                data={[
                  summary.oneHour.long,
                  summary.oneHour.short,
                  summary.oneHour.none,
                ]}
                labels={labels}
                colors={colors}
              ></PieChart>
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">4h</div>
            <div className="stat-value">
              <PieChart
                data={[
                  summary.fourHours.long,
                  summary.fourHours.short,
                  summary.fourHours.none,
                ]}
                labels={labels}
                colors={colors}
              ></PieChart>
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">1d</div>
            <div className="stat-value">
              <PieChart
                data={[
                  summary.oneDay.long,
                  summary.oneDay.short,
                  summary.oneDay.none,
                ]}
                labels={labels}
                colors={colors}
              ></PieChart>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="p-4">
      <div className="stats bg-primary text-primary-content"></div>
      {GetSignalChart()}
    </div>
  )
}

export default SpotlightSummary
