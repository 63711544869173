import type { FC } from 'react'

import type { ITabsProps } from '../../../global/type'

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab number
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 */
const Tabs: FC<ITabsProps> = ({
  className = 'tabs-component',
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = 'horizontal',
  addButton = false,
  onAddButton,
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab)

  return (
    <div
      className={
        orientation === 'vertical' ? className + ' vertical' : className
      }
    >
      <div className="tabs" role="tablist" aria-orientation={orientation}>
        {tabs.map((tab) => (
          <button
            className={
              'mb-5 tab tab-bordered ' +
              (selectedTab === tab.index ? 'tab-active' : '')
            }
            onClick={() => onClick(tab.index)}
            key={tab.index}
            type="button"
            role="tab"
            aria-selected={selectedTab === tab.index}
            aria-controls={`tabpanel-${tab.index}`}
            tabIndex={selectedTab === tab.index ? 0 : -1}
            id={`btn-${tab.index}`}
          >
            {tab.label}
          </button>
        ))}
        {addButton && (
          <button
            className="tab tab-bordered mb-5"
            onClick={onAddButton}
            key={'add'}
            type="button"
            role="tab"
            aria-selected={false}
            aria-controls={`tabpanel-${'add'}`}
            tabIndex={-1}
            id={`btn-${'add'}`}
          >
            +
          </button>
        )}
      </div>
      <div
        role="tabpanel"
        aria-labelledby={`btn-${selectedTab}`}
        id={`tabpanel-${selectedTab}`}
      >
        {Panel && (
          <Panel.Component index={selectedTab} data-props={Panel.props} />
        )}
      </div>
    </div>
  )
}
export default Tabs
