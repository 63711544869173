import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import LayoutMain from '../../layouts'
import DeskWidget from '../../pages/desk/deskWidget'
import Scenario from '../../pages/scenarioPage/scenario'
import Tools from '../../pages/toolsPage/tools'
import Workflows from '../../pages/workflow/workflows'

const Backtest = lazy(() => import('../../pages/backtestPage/backtest'))
const Bots = lazy(() => import('../../pages/botsPage/bots'))
const Dashboard = lazy(() => import('../../pages/dashboardPage/dashboard'))
const Settings = lazy(() => import('../../pages/settingsPage/settings'))

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Dashboard />
            </Suspense>
          }
        />
      </Route>

      <Route path="/bots" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Bots />
            </Suspense>
          }
        />
      </Route>

      <Route path="/scenarios" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Scenario />
            </Suspense>
          }
        />
      </Route>

      <Route path="/workflow" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Workflows />
            </Suspense>
          }
        />
      </Route>

      <Route path="/settings" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Settings />
            </Suspense>
          }
        />
      </Route>

      <Route path="/backtest" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Backtest />
            </Suspense>
          }
        />
      </Route>
      <Route path="/tools" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Tools />
            </Suspense>
          }
        />
      </Route>

      <Route path="/desk" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <DeskWidget />
            </Suspense>
          }
        />
      </Route>
      {/* <Route path="/web3" element={<LayoutMain />}>
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Web3 />
            </Suspense>
          }
        />
      </Route> */}
    </Routes>
  )
}

export default MyRoutes
