import { useCallback } from 'react'
import type { NodeProps } from 'reactflow'

import { WorkflowSelector } from '../../../../../app/store/selectors/workflowSelector'
import useWorkflowStore from '../../../../../app/store/workflowStore'
import type { IFlow } from '../../../../../generated/ManagingApi'
import { Timeframe, Ticker } from '../../../../../generated/ManagingApi'
import { FormInput } from '../../../../mollecules'
import Flow from '../Flow'

const FeedTicker = ({ data, isConnectable, id }: NodeProps<IFlow>) => {
  const { updateNodeData } = useWorkflowStore(WorkflowSelector)

  const onTickerChange = useCallback((evt: any) => {
    updateNodeData(id, 'Ticker', evt.target.value)
  }, [])

  const onTimeframeChange = useCallback((evt: any) => {
    updateNodeData(id, 'Timeframe', evt.target.value)
  }, [])

  return (
    <Flow
      name={data.name}
      description={data.description}
      inputs={data.acceptedInputs}
      outputs={data.outputTypes}
      isConnectable={isConnectable}
    >
      <FormInput label="Timeframe" htmlFor="period">
        <select
          className="select no-drag w-full max-w-xs"
          onChange={onTimeframeChange}
          value={
            data.parameters.find((p) => p.name === 'Timeframe')?.value || ''
          }
        >
          {Object.keys(Timeframe).map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </FormInput>
      <FormInput label="Ticker" htmlFor="ticker">
        <select
          className="select no-drag w-full max-w-xs"
          onChange={onTickerChange}
          value={data.parameters.find((p) => p.name === 'Ticker')?.value || ''}
        >
          {Object.keys(Ticker).map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </FormInput>
    </Flow>
  )
}

export default FeedTicker
