import { useQuery } from '@tanstack/react-query'

import useApiUrlStore from '../app/store/apiStore'
import { AccountClient, type Account } from '../generated/ManagingApi'

type UseAccountsProps = {
  callback?: (data: Account[]) => void | undefined
}

const useAcconuts = ({ callback }: UseAccountsProps) => {
  const { apiUrl } = useApiUrlStore()
  const accountClient = new AccountClient({}, apiUrl)

  const query = useQuery({
    onSuccess: (data) => {
      if (data && callback) {
        callback(data)
      }
    },
    queryFn: () => accountClient.account_GetAccounts(),
    queryKey: ['accounts'],
  })

  return query
}

export default useAcconuts
