import { useState, useEffect } from 'react'

import { Tabs } from '../../components/mollecules'
import type { ITabsType } from '../../global/type'

import RektFees from './rektFees'
import SpotlightView from './spotlight/spotlight'

const tabs: ITabsType = [
  {
    Component: SpotlightView,
    index: 1,
    label: 'Spotlight',
  },
  {
    Component: RektFees,
    index: 2,
    label: 'RektFees',
  },
]

const Tools: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  useEffect(() => {}, [])

  return (
    <div>
      <div className="container mx-auto">
        <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} />
      </div>
    </div>
  )
}

export default Tools
