import { TrashIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'

import useApiUrlStore from '../../app/store/apiStore'
import { Toast, Table } from '../../components/mollecules'
import type { Scenario, Strategy } from '../../generated/ManagingApi'
import { ScenarioClient } from '../../generated/ManagingApi'
import type { IScenarioList } from '../../global/type'

const ScenarioTable: React.FC<IScenarioList> = ({ list }) => {
  const [rows, setRows] = useState<Scenario[]>([])
  const { apiUrl } = useApiUrlStore()

  async function deleteScenario(id: string) {
    const t = new Toast('Deleting scenario')
    const client = new ScenarioClient({}, apiUrl)

    await client
      .scenario_DeleteScenario(id)
      .then(() => {
        t.update('success', 'Scenario deleted')
      })
      .catch((err) => {
        t.update('error', err)
      })
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Cell: ({ cell }: any) => (
          <>
            {cell.row.values.strategies.map((strategy: Strategy) => (
              <div
                key={strategy.name}
                className="badge badge-primary badge-outline"
              >
                {strategy.name}
              </div>
            ))}
          </>
        ),
        Header: 'Strategies',
        accessor: 'strategies',
        disableFilters: true,
      },
      {
        Cell: ({ cell }: any) => (
          <>
            <div className="tooltip" data-tip="Delete scenario">
              <button
                data-value={cell.row.values.name}
                onClick={() => deleteScenario(cell.row.values.name)}
              >
                <TrashIcon className="text-accent w-4"></TrashIcon>
              </button>
            </div>
          </>
        ),
        Header: '',
        accessor: 'id',
        disableFilters: true,
      },
    ],
    []
  )

  useEffect(() => {
    setRows(list)
  }, [list])

  return (
    <div className="flex flex-wrap">
      <Table columns={columns} data={rows} showPagination={true} />
    </div>
  )
}

export default ScenarioTable
