import React from 'react'

import type { IFormInput } from '../../../global/type'

const FormInput: React.FC<IFormInput> = ({
  children,
  label,
  htmlFor,
  inline = false,
}) => {
  const groupStyle = inline ? 'flex-wrap' : ''
  return (
    <div className="form-control mb-2">
      <div className={'input-group ' + groupStyle}>
        <label htmlFor={htmlFor} className={'label h-auto w-full'}>
          {label}
        </label>
        {children}
      </div>
    </div>
  )
}

export default FormInput
