import type { CardProps } from '../../../global/type'

const Card = ({ name, children, showCloseButton, info }: CardProps) => {
  return (
    <div
      key={name}
      className={`card bg-base-200 sm:hover:bg-base-300 transition border border-base-100 sm:hover:border-base-200`}
    >
      <div className="flex border-b-[1px] border-base-300">
        <div className="card-title drag-handle justify-start w-full ml-3">
          {name}
          {info && (
            <div className="tooltip" data-tip={info}>
              <span className="badge badge-outline badge-accent ml-2">i</span>
            </div>
          )}
        </div>

        <div className="card-actions justify-self-end">
          {showCloseButton && (
            <button className="btn btn-square btn-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  )
}

export default Card
