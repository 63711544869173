import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import React from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
} from 'react-table'

import type { TableInstanceWithHooks } from '../../../global/type'

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

export default function Table({
  columns,
  data,
  renderRowSubCompontent,
  showPagination,
  hiddenColumns,
  showTotal = false,
}: any) {
  const defaultColumn = React.useMemo<any>(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      initialState: {
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  ) as TableInstanceWithHooks<any>

  // Calculez le total des valeurs dans la colonne USD
  const total = data
    ? data
        .reduce((sum: number, row: any) => {
          return sum + (row.value || 0) // Si la valeur est undefined = 0
        }, 0)
        .toFixed(2) + ' $'
    : '0.00 $'

  // Render the UI for your table
  return (
    <>
      <div className="w-full mt-3 mb-3 overflow-x-auto">
        <table {...getTableProps()} className="table-compact table">
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <p className="mb-2 text-center">
                      {column.render('Header')}
                    </p>
                    <span className="relative">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowUpIcon className="text-primary absolute right-0 w-4" />
                        ) : (
                          <ArrowDownIcon className="text-secondary absolute right-0 w-4" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row)
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {/*
                              Inside it, call our renderRowSubComponent function. In reality,
                              you could pass whatever you want as props to
                              a component like this, including the entire
                              table instance. But for this example, we'll just
                              pass the row
                            */}
                        {renderRowSubCompontent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </>
              )
            })}
          </tbody>
          {/* Afficher le total ici */}
          {showTotal ? (
            <tr>
              <td colSpan={visibleColumns.length}>
                <strong>Total: {total}</strong>
              </td>
            </tr>
          ) : null}
        </table>
      </div>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <br />
      {showPagination ? (
        <div className="pagination">
          <button
            className="btn"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>{' '}
          <button
            className="btn"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <button
            className="btn"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>{' '}
          <button
            className="btn"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          {/* <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '} */}
          <select
            className="select select-bordered"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </>
  )
}
