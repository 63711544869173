import moment from 'moment'
import { useEffect, useState } from 'react'

import useApiUrlStore from '../../../app/store/apiStore'
import type {
  Spotlight,
  SpotlightOverview,
} from '../../../generated/ManagingApi'
import { DataClient } from '../../../generated/ManagingApi'

import SpotlightSummary from './spotlightSummary'
import SpotlightTable from './spotlightTable'

interface ISpotlightOverview {
  overview: SpotlightOverview | undefined
}
const Overview: React.FC<ISpotlightOverview> = ({ overview }) => {
  return (
    <>
      <div>Last update : {moment(overview?.dateTime).fromNow()}</div>
      <div className="flex flex-wrap m-3 -mx-3">
        <SpotlightSummary spotlights={overview?.spotlights}></SpotlightSummary>
      </div>
      <div className="flex flex-wrap m-3 -mx-3">
        {overview?.spotlights.map((s) => (
          <SpotlightDetail spotlight={s}></SpotlightDetail>
        ))}
      </div>
    </>
  )
}

interface ISpotlight {
  spotlight: Spotlight
}
const SpotlightDetail: React.FC<ISpotlight> = ({ spotlight }) => {
  return (
    <>
      <div className="sm:w-1/2 md:w-1/3 xl:w-1/3 w-full p-1">
        <SpotlightTable spotlight={spotlight} />
      </div>
    </>
  )
}

const SpotlightView = () => {
  const [overview, setOverview] = useState<SpotlightOverview>()
  const [isFetching, setIsFetching] = useState(true)

  const { apiUrl } = useApiUrlStore()
  const dataClient = new DataClient({}, apiUrl)

  useEffect(() => {
    dataClient
      .data_GetSpotlight()
      .then((data) => {
        setOverview(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }, [])

  return (
    <>
      <div className="container mx-auto">
        {isFetching ? (
          <progress className="progress progress-primary w-56"></progress>
        ) : (
          <Overview overview={overview}></Overview>
        )}
      </div>
    </>
  )
}
export default SpotlightView
