import { Handle, Position } from 'reactflow'

import type { FlowOutput } from '../../../../generated/ManagingApi'
import type { IFlowProps } from '../../../../global/type'
import { Card } from '../../../mollecules'

const Flow = ({
  name,
  description,
  children,
  inputs,
  outputs,
  isConnectable,
}: IFlowProps) => {
  return (
    <Card name={name} info={description}>
      {inputs?.map((input: FlowOutput) => {
        return (
          <Handle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
          />
        )
      })}
      {children}
      {outputs?.map((output: FlowOutput) => {
        return (
          <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
          />
        )
      })}
    </Card>
  )
}

export default Flow
