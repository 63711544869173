import { Auth } from '../pages/authPage/auth'

import MyRoutes from './routes'

const App = () => {
  return (
    <Auth>
      <MyRoutes />
    </Auth>
  )
}

export default App
