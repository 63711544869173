import './styles/globals.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConnectKitProvider, getDefaultConfig } from 'connectkit'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { WagmiConfig, createConfig } from 'wagmi'

import App from './app'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'react-toastify/dist/ReactToastify.css'

const config = createConfig(
  getDefaultConfig({
    alchemyId: import.meta.env.VITE_ALCHEMY_ID,
    appName: 'Managing App',
    walletConnectProjectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
  })
)

const element = document.getElementById('root') as HTMLElement

const root = createRoot(element)
const queryClient = new QueryClient()

root.render(
  <QueryClientProvider client={queryClient}>
    <WagmiConfig config={config}>
      <ConnectKitProvider theme="auto">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConnectKitProvider>
    </WagmiConfig>
  </QueryClientProvider>
)
