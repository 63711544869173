import React from 'react'

import type { IFlow } from '../../../generated/ManagingApi'

type IFlowItem = {
  onDragStart: (event: any, data: string) => void
  flow: IFlow
}

const FlowItem: React.FC<IFlowItem> = ({ onDragStart, flow }) => {
  return (
    <div
      className="btn btn-primary btn-xs w-full h-full my-2"
      onDragStart={(event) => onDragStart(event, `${flow.type}`)}
      draggable
    >
      {flow.name}
    </div>
  )
}

export default FlowItem
