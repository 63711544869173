import moment from 'moment'

import { TradeDirection } from '../../../generated/ManagingApi'
import type { ISpotlightBadge } from '../../../global/type'

function GetBadgeColor(direction: TradeDirection | undefined) {
  switch (direction) {
    case TradeDirection.Long:
      return 'badge bg-success'
    case TradeDirection.Short:
      return 'badge bg-error'
    case TradeDirection.None:
      return 'badge bg-warning'
    default:
      return 'badge'
  }
}

export default function SpotLightBadge({
  direction,
  date,
  price,
}: ISpotlightBadge) {
  const tooltipText =
    date == undefined ? 'No signal' : moment(date).fromNow() + ' @ ' + price
  return (
    <div className="tooltip z-20" data-tip={tooltipText}>
      <div className={GetBadgeColor(direction)}></div>
    </div>
  )
}
