import React, { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'

import useApiUrlStore from '../../app/store/apiStore'
import { Modal, Toast } from '../../components/mollecules'
import type { Scenario, Strategy } from '../../generated/ManagingApi'
import { ScenarioClient } from '../../generated/ManagingApi'
import type { IScenarioFormInput } from '../../global/type'

import ScenarioTable from './scenarioTable'

const ScenarioList: React.FC = () => {
  const [strategies, setStrategies] = useState<Strategy[]>([])
  const [scenarios, setScenarios] = useState<Scenario[]>([])
  const [showModal, setShowModal] = useState(false)
  const { register, handleSubmit } = useForm<IScenarioFormInput>()
  const { apiUrl } = useApiUrlStore()
  const client = new ScenarioClient({}, apiUrl)

  async function createScenario(form: IScenarioFormInput) {
    const t = new Toast('Creating scenario')
    await client
      .scenario_CreateScenario(form.name, form.strategies)
      .then((data: Scenario) => {
        t.update('success', 'Scenario created')
        setScenarios((arr) => [...arr, data])
      })
      .catch((err) => {
        t.update('error', err)
      })
  }

  const onSubmit: SubmitHandler<IScenarioFormInput> = async (form) => {
    closeModal()
    await createScenario(form)
  }

  useEffect(() => {
    client.scenario_GetScenarios().then((data) => {
      setScenarios(data)
    })
    client.scenario_GetStrategies().then((data) => {
      setStrategies(data)
    })
  }, [])

  function openModal() {
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  return (
    <div className="container mx-auto">
      <button className="btn" onClick={openModal}>
        Create new scenario
      </button>
      <ScenarioTable list={scenarios} />
      <Modal
        {...{
          onClose: closeModal,
          onSubmit: handleSubmit(onSubmit),
          showModal,
          titleHeader: 'Scenario Builder',
        }}
      >
        <div className="form-control mb-5">
          <div className="input-group">
            <label htmlFor="name" className="label mr-6">
              Name
            </label>
            <input
              className="bg-inherit w-full max-w-xs"
              {...register('name')}
            ></input>
          </div>
        </div>
        <div className="form-control">
          <div className="input-group">
            <label htmlFor="strategies" className="label mr-6">
              Strategies
            </label>
            <select
              multiple
              className="select select-bordered h-28 w-full max-w-xs"
              {...register('strategies')}
            >
              {strategies.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.signalType} - {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="modal-action">
          <button type="submit" className="btn">
            Build
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ScenarioList
