import type { IFlow } from '../../../generated/ManagingApi'
import { Loader } from '../../atoms'

import FlowItem from './flowItem'

type IWorkflowSidebar = {
  flows?: IFlow[]
  isLoading: boolean
}

const WorkflowSidebar = ({ flows, isLoading = true }: IWorkflowSidebar) => {
  const onDragStart = (event: any, data: string) => {
    event.dataTransfer.setData('application/reactflow', data)
    event.dataTransfer.effectAllowed = 'move'
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <aside>
      <div className="bg-base-200 p-4">
        <div className="mb-2 text-lg">Flows</div>
        {flows
          ? flows.map((flow) => (
              <FlowItem flow={flow} onDragStart={onDragStart} />
            ))
          : null}
      </div>
    </aside>
  )
}

export default WorkflowSidebar
