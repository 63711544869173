import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import type { Edge, Node } from 'reactflow'

import useApiUrlStore from '../../app/store/apiStore'
import { Loader } from '../../components/atoms'
import { Tabs } from '../../components/mollecules'
import { WorkflowCanvas } from '../../components/organism'
import type {
  IFlow,
  SyntheticFlow,
  SyntheticWorkflow,
} from '../../generated/ManagingApi'
import { WorkflowClient } from '../../generated/ManagingApi'
import type { ITabsType, IWorkflow } from '../../global/type'

const mapWorkflowToTabs = (workflows: SyntheticWorkflow[]): ITabsType => {
  return workflows.map((workflow: SyntheticWorkflow, index: number) => {
    return {
      Component: WorkflowCanvas,
      index: index,
      label: workflow.name,
      props: mapFlowsToNodes(workflow.flows, workflow.name),
    }
  })
}

const mapFlowsToNodes = (flows: SyntheticFlow[], name: string): IWorkflow => {
  const nodes: Node<IFlow>[] = []
  const edges: Edge[] = []

  flows.forEach((flow: SyntheticFlow) => {
    nodes.push(mapFlowToNode(flow))
  })

  for (const node of nodes) {
    const childrenNodes = nodes.filter((n) => n.data.parentId == node.data.id)

    if (childrenNodes.length > 0) {
      childrenNodes.forEach((childNode) => {
        edges.push({
          id: `${node.id}-${childNode.id}`,
          source: node.id,
          target: childNode.id,
        })
      })
    }
  }

  return { edges, name: name, nodes } as IWorkflow
}

const mapFlowToNode = (flow: SyntheticFlow): Node => {
  return {
    data: flow,
    id: flow.id,
    position: { x: 0, y: 0 },
    type: flow.type,
  }
}

const Workflows: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1)
  const { apiUrl } = useApiUrlStore()
  const client = new WorkflowClient({}, apiUrl)

  const { data, isLoading } = useQuery({
    onSuccess: () => {
      setSelectedTab(0)
    },
    queryFn: () => client.workflow_GetWorkflows(),
    queryKey: ['workflows'],
  })

  useEffect(() => {}, [isLoading])

  if (isLoading || data == null) {
    return <Loader></Loader>
  }

  return (
    <div>
      <div className="container mx-auto">
        <Tabs
          selectedTab={selectedTab}
          onClick={setSelectedTab}
          tabs={mapWorkflowToTabs(data)}
          addButton={true}
          onAddButton={() => {
            console.log('add button clicked')
          }}
        />
      </div>
    </div>
  )
}

export default Workflows
