import * as React from 'react'
import Plot from 'react-plotly.js'

type IPieChart = {
  data: number[]
  labels: string[]
  colors: string[]
}

const PieChart: React.FC<IPieChart> = ({ data, labels, colors }) => {
  return (
    <>
      <Plot
        data={[
          {
            labels: labels,
            marker: {
              colors: colors,
            },
            type: 'pie',
            values: data,
          },
        ]}
        layout={{
          height: 150,
          margin: {
            b: 20,
            l: 0,
            pad: 0,
            r: 0,
            t: 0,
          },
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          showlegend: false,
          width: 150,
        }}
        config={{
          displayModeBar: false,
        }}
      />
    </>
  )
}

export default PieChart
