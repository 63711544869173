import React, { useEffect, useState } from 'react'

import 'react-toastify/dist/ReactToastify.css'
import { Tabs } from '../../components/mollecules'
import type { TabsType } from '../../global/type'

import ScenarioList from './scenarioList'
import StrategyList from './strategyList'

// Tabs Array
const tabs: TabsType = [
  {
    Component: ScenarioList,
    index: 1,
    label: 'Scenarios',
  },
  {
    Component: StrategyList,
    index: 2,
    label: 'Strategies',
  },
]

const Backtest: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  useEffect(() => {}, [])

  return (
    <div>
      <div className="container mx-auto">
        <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} />
      </div>
    </div>
  )
}

export default Backtest
