import React, { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'

import useApiUrlStore from '../../app/store/apiStore'
import { Toast } from '../../components/mollecules'
import type { Strategy } from '../../generated/ManagingApi'
import {
  StrategyType,
  ScenarioClient,
  Timeframe,
} from '../../generated/ManagingApi'

import StrategyTable from './strategyTable'

interface IStrategyFormInput {
  type: StrategyType
  timeframe: Timeframe
  name: string
  period: number
  fastPeriods: number
  slowPeriods: number
  signalPeriods: number
  multiplier: number
  stochPeriods: number
  smoothPeriods: number
  cyclePeriods: number
}

const StrategyList: React.FC = () => {
  const [strategyType, setStrategyType] = useState<StrategyType>(
    StrategyType.RsiDivergence
  )
  const [strategies, setStrategies] = useState<Strategy[]>([])
  const [showModal, setShowModal] = useState(false)
  const { register, handleSubmit } = useForm<IStrategyFormInput>()
  const { apiUrl } = useApiUrlStore()
  const scenarioClient = new ScenarioClient({}, apiUrl)

  async function createStrategy(form: IStrategyFormInput) {
    const t = new Toast('Creating strategy')
    await scenarioClient
      .scenario_CreateStrategy(
        form.type,
        form.timeframe,
        form.name,
        form.period,
        form.fastPeriods,
        form.slowPeriods,
        form.signalPeriods,
        form.multiplier,
        form.stochPeriods,
        form.smoothPeriods,
        form.cyclePeriods
      )
      .then((strategy: Strategy) => {
        t.update('success', 'Strategy created')
        setStrategies((arr) => [...arr, strategy])
      })
      .catch((err) => {
        t.update('error', err)
      })
  }

  function setStrategyTypeEvent(e: any) {
    setStrategyType(e.target.value)
  }

  const onSubmit: SubmitHandler<IStrategyFormInput> = async (form) => {
    closeModal()
    await createStrategy(form)
  }

  useEffect(() => {
    scenarioClient.scenario_GetStrategies().then((data) => {
      setStrategies(data)
    })
  }, [])

  function openModal() {
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  return (
    <div>
      <div className="container mx-auto">
        <button className="btn" onClick={openModal}>
          Create strategy
        </button>
        <StrategyTable list={strategies} />
        {showModal ? (
          <>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal modal-bottom sm:modal-middle modal-open">
                  <div className="modal-box">
                    <button
                      onClick={closeModal}
                      className="btn btn-sm btn-circle right-2 top-2 absolute"
                    >
                      ✕
                    </button>
                    <div className="text-primary mb-3 text-lg">
                      Strategy builder
                    </div>
                    <div className="form-control">
                      <div className="input-group">
                        <label htmlFor="name" className="label mr-6">
                          Name
                        </label>
                        <input
                          className="bg-inherit w-full max-w-xs"
                          {...register('name')}
                        ></input>
                      </div>
                    </div>
                    <div className="form-control">
                      <div className="input-group">
                        <label htmlFor="type" className="label mr-6">
                          Type
                        </label>
                        <select
                          className="select w-full max-w-xs"
                          {...register('type', {
                            onChange: (e) => {
                              setStrategyTypeEvent(e)
                            },
                          })}
                        >
                          {Object.keys(StrategyType).map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-control">
                      <div className="input-group">
                        <label htmlFor="timeframe" className="label mr-6">
                          Timeframe
                        </label>
                        <select
                          className="select w-full max-w-xs"
                          {...register('timeframe')}
                        >
                          {Object.keys(Timeframe).map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {strategyType == StrategyType.EmaTrend ||
                    strategyType == StrategyType.RsiDivergence ||
                    strategyType == StrategyType.StDev ||
                    strategyType == StrategyType.EmaCross ||
                    strategyType == StrategyType.RsiDivergenceConfirm ? (
                      <>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Period
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="5"
                                className="input"
                                {...register('period')}
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {strategyType == StrategyType.MacdCross ? (
                      <>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Fast Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="12"
                                className="input"
                                {...register('fastPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Slow Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="26"
                                className="input"
                                {...register('slowPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Signal Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="9"
                                className="input"
                                {...register('signalPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {strategyType == StrategyType.Stc ? (
                      <>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Fast Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="23"
                                className="input"
                                {...register('fastPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Slow Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="50"
                                className="input"
                                {...register('slowPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Cycle Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="10"
                                className="input"
                                {...register('cyclePeriods')}
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {strategyType == StrategyType.SuperTrend ||
                    strategyType == StrategyType.ChandelierExit ? (
                      <>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Period
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="10"
                                className="input"
                                {...register('period')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="multiplier" className="label mr-6">
                              Multiplier
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="3"
                                className="input"
                                {...register('multiplier')}
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {strategyType == StrategyType.StochRsiTrend ? (
                      <>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="14"
                                className="input"
                                {...register('period')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Stoch Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="14"
                                className="input"
                                {...register('stochPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Signal Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="3"
                                className="input"
                                {...register('signalPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="form-control">
                          <div className="input-group">
                            <label htmlFor="period" className="label mr-6">
                              Smooth Periods
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                placeholder="1"
                                className="input"
                                {...register('smoothPeriods')}
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="modal-action">
                      <button type="submit" className="btn">
                        Build
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default StrategyList
