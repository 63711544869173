import create from 'zustand'

type ApiStore = {
  isProd: boolean
  apiUrl: string
  workerUrl: string
  toggleApiUrl: () => void
}

const useApiUrlStore = create<ApiStore>((set) => ({
  // Mettez la valeur initiale de isProd ici
  apiUrl: import.meta.env.VITE_API_URL_SERVER,
  isProd: true,
  toggleApiUrl: () => {
    set((state) => ({
      apiUrl: state.isProd
        ? import.meta.env.VITE_API_URL_LOCAL
        : import.meta.env.VITE_API_URL_SERVER,
      isProd: !state.isProd,
      workerUrl: state.isProd
        ? import.meta.env.VITE_WORKER_URL_LOCAL
        : import.meta.env.VITE_WORKER_URL_SERVER,
    }))
  },
  workerUrl: import.meta.env.VITE_WORKER_URL_SERVER,
}))

export default useApiUrlStore
