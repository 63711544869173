/**
 * Configuration class needed in base class.
 * The config is provided to the API client at initialization time.
 * API clients inherit from #AuthorizedApiBase and provide the config.
 */

import { Cookies } from 'react-cookie'

import type IConfig from './IConfig'
export default class AuthorizedApiBase {
  private readonly config: IConfig

  constructor(config: IConfig) {
    this.config = config
  }

  transformOptions = (options: any): Promise<RequestInit> => {
    const cookies = new Cookies()

    // Retrieve the bearer token from the cookies
    const bearerToken = cookies.get('token')
    // eslint-disable-next-line no-console
    console.log(bearerToken)
    if (bearerToken) {
      options.headers.Authorization = `Bearer ${bearerToken}`
    }

    return Promise.resolve(options)
  }
}
