import React from 'react'

import { Table } from '../../../components/mollecules'
import { SpotLightBadge } from '../../../components/organism'
import type { Signal, Spotlight } from '../../../generated/ManagingApi'
import { TradeDirection } from '../../../generated/ManagingApi'

interface ISpotlightTable {
  spotlight: Spotlight
}
const GetBadgeForTimeframe = (signals: Signal[]) => {
  const lastSignal = signals[signals.length > 1 ? signals.length - 1 : 0]
  return lastSignal ? (
    <SpotLightBadge
      direction={lastSignal.direction}
      date={lastSignal.date}
      price={lastSignal.candle?.close}
    />
  ) : (
    <SpotLightBadge direction={TradeDirection.None} />
  )
}

const SpotlightTable: React.FC<ISpotlightTable> = ({ spotlight }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: spotlight.scenario.name,
        columns: [
          {
            Header: 'Ticker',
            accessor: 'ticker',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Cell: ({ cell }: any) => (
              <>{GetBadgeForTimeframe(cell.row.values.fiveMinutes)}</>
            ),
            Header: '5m',
            accessor: 'fiveMinutes',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Cell: ({ cell }: any) => (
              <>{GetBadgeForTimeframe(cell.row.values.fifteenMinutes)}</>
            ),
            Header: '15m',
            accessor: 'fifteenMinutes',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Cell: ({ cell }: any) => (
              <>{GetBadgeForTimeframe(cell.row.values.oneHour)}</>
            ),
            Header: '1h',
            accessor: 'oneHour',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Cell: ({ cell }: any) => (
              <>{GetBadgeForTimeframe(cell.row.values.fourHour)}</>
            ),
            Header: '4h',
            accessor: 'fourHour',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Cell: ({ cell }: any) => (
              <>{GetBadgeForTimeframe(cell.row.values.oneDay)}</>
            ),
            Header: '1D',
            accessor: 'oneDay',
            disableFilters: true,
            disableSortBy: true,
          },
        ],
      },
    ],
    []
  )

  return (
    <div className="flex flex-wrap">
      <Table columns={columns} data={spotlight.tickerSignals} />
    </div>
  )
}

export default SpotlightTable
