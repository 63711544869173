import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import type { NodeProps } from 'reactflow'

import useApiUrlStore from '../../../../../app/store/apiStore'
import { WorkflowSelector } from '../../../../../app/store/selectors/workflowSelector'
import useWorkflowStore from '../../../../../app/store/workflowStore'
import type { Account, IFlow } from '../../../../../generated/ManagingApi'
import { MoneyManagementClient } from '../../../../../generated/ManagingApi'
import useAccounts from '../../../../../hooks/useAccounts'
import { Loader } from '../../../../atoms'
import { FormInput } from '../../../../mollecules'
import Flow from '../Flow'

const OpenPositionFlow = ({ data, isConnectable, id }: NodeProps<IFlow>) => {
  const { updateNodeData } = useWorkflowStore(WorkflowSelector)
  const { apiUrl } = useApiUrlStore()

  const [selectedAccount, setSelectedAccount] = React.useState<string>()
  const [selectedMoneyManagement, setSelectedMoneyManagement] =
    useState<string>()
  const moneyManagementClient = new MoneyManagementClient({}, apiUrl)

  const { data: accounts } = useAccounts({
    callback: (data: Account[]) => {
      setSelectedAccount(data[0].name)
    },
  })

  const { data: moneyManagements } = useQuery({
    onSuccess: (data) => {
      if (data) {
        setSelectedMoneyManagement(data[0].name)
      }
    },
    queryFn: () => moneyManagementClient.moneyManagement_GetMoneyManagements(),
    queryKey: ['moneyManagement'],
  })

  const onAccountChange = useCallback((evt: any) => {
    updateNodeData(id, 'Account', evt.target.value)
  }, [])

  const onMoneyManagementChange = useCallback((evt: any) => {
    updateNodeData(id, 'MoneyManagement', evt.target.value)
  }, [])

  if (!accounts || !moneyManagements) {
    return <Loader />
  }

  return (
    <Flow
      name={data.name || ''}
      description={data.description}
      inputs={data.acceptedInputs}
      outputs={data.outputTypes}
      isConnectable={isConnectable}
    >
      <FormInput label="Account" htmlFor="accountName">
        <select
          className="select select-bordered w-full h-auto max-w-xs"
          onChange={(evt) => onAccountChange(evt.target.value)}
          value={
            data.parameters.find((p) => p.name === 'Account')?.value ||
            selectedAccount
          }
        >
          {accounts.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </FormInput>
      <FormInput label="Money Management" htmlFor="moneyManagement">
        <select
          className="select w-full max-w-xs"
          onChange={(evt) => onMoneyManagementChange(evt.target.value)}
          value={
            data.parameters.find((p) => p.name === 'MoneyManagement')?.value ||
            selectedMoneyManagement
          }
        >
          {moneyManagements.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </FormInput>
    </Flow>
  )
}

export default OpenPositionFlow
