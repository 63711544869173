import React from 'react'

import type { IModalProps } from '../../../global/type'

import ModalHeader from './ModalHeader'

const Modal: React.FC<IModalProps> = ({
  showModal,
  onSubmit,
  onClose,
  titleHeader,
  children,
}) => {
  return (
    <div className="container mx-auto">
      {showModal ? (
        <form onSubmit={onSubmit}>
          <div className="modal modal-bottom sm:modal-middle modal-open">
            <div className="modal-box">
              <ModalHeader
                titleHeader={titleHeader}
                onClose={onClose}
                onSubmit={onSubmit}
              />
              {children}
            </div>
          </div>
        </form>
      ) : null}
    </div>
  )
}

export default Modal
