import React, { Suspense } from 'react'

import type { IWidgetProperties } from '../../global/type'

const loadWidget = (component: any) => {
  return React.lazy(() => import(`./widgets/${component}.tsx`))
}

const DeskWidget = React.forwardRef((props: any, ref) => {
  const widget = props
  const widgetProperties = widget['data-properties'] as IWidgetProperties
  const WidgetComponent = loadWidget(widgetProperties.id)
  return (
    <div ref={ref} {...props}>
      <Suspense fallback={<>Loading</>}>
        <WidgetComponent />
        {widget.children}
      </Suspense>
    </div>
  )
})

export default DeskWidget