import { TrashIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'

import useApiUrlStore from '../../app/store/apiStore'
import { SelectColumnFilter, Table, Toast } from '../../components/mollecules'
import type { Strategy } from '../../generated/ManagingApi'
import { ScenarioClient } from '../../generated/ManagingApi'

interface IStrategyList {
  list: Strategy[]
}

const StrategyTable: React.FC<IStrategyList> = ({ list }) => {
  const [rows, setRows] = useState<Strategy[]>([])
  const { apiUrl } = useApiUrlStore()

  async function deleteBacktest(id: string) {
    const t = new Toast('Deleting strategy')
    const client = new ScenarioClient({}, apiUrl)

    await client
      .scenario_DeleteStrategy(id)
      .then(() => {
        t.update('info', 'Strategy deleted')
      })
      .catch((err) => {
        t.update('error', err)
      })
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Filter: SelectColumnFilter,
        Header: 'Type',
        accessor: 'type',
        disableSortBy: true,
      },
      {
        Filter: SelectColumnFilter,
        Header: 'Timeframe',
        accessor: 'timeframe',
        disableSortBy: true,
      },
      {
        Filter: SelectColumnFilter,
        Header: 'Signal',
        accessor: 'signalType',
        disableSortBy: true,
      },
      {
        Filter: SelectColumnFilter,
        Header: 'Period',
        accessor: 'period',
        disableSortBy: true,
      },
      {
        Cell: ({ cell }: any) => (
          <>
            <div className="tooltip" data-tip="Delete strategy">
              <button
                data-value={cell.row.values.name}
                onClick={() => deleteBacktest(cell.row.values.name)}
              >
                <TrashIcon className="text-accent w-4"></TrashIcon>
              </button>
            </div>
          </>
        ),
        Header: '',
        accessor: 'id',
        disableFilters: true,
      },
    ],
    []
  )

  useEffect(() => {
    setRows(list)
  }, [list])

  return (
    <div className="flex flex-wrap">
      <Table columns={columns} data={rows} showPagination={true} />
    </div>
  )
}

export default StrategyTable
