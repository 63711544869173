import { useIsFetching } from '@tanstack/react-query'
import { ConnectKitButton } from 'connectkit'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { NavItem } from '..'
import useApiUrlStore from '../../../app/store/apiStore'
import Logo from '../../../assets/img/logo.png'
import { Loader } from '../../atoms'

const navigation = [
  { href: '/desk', name: 'Desk' },
  { href: '/bots', name: 'Bots' },
  { href: '/workflow', name: 'Workflows' },
  { href: '/scenarios', name: 'Scenarios' },
  { href: '/backtest', name: 'Backtest' },
  { href: '/tools', name: 'Tools' },
  { href: '/settings', name: 'Settings' },
]

function navItems(isMobile = false) {
  return navigation.map((item) => (
    <NavItem key={item.name} href={item.href} isMobile={isMobile}>
      {item.name}
    </NavItem>
  ))
}

function PrimaryNavbar() {
  return (
    <div className="flex">
      <Link className="btn btn-ghost text-xl normal-case" to={'/'}>
        <img src={Logo} className="App-logo" alt="logo" />
      </Link>
      {/* <NavItem href="#" /> */}
      <div className="md:flex items-center hidden space-x-1">{navItems()}</div>
    </div>
  )
}

const GlobalLoader = () => {
  const isFetching = useIsFetching()
  return isFetching ? <Loader size="xs"></Loader> : null
}

export function SecondaryNavbar() {
  const { toggleApiUrl, isProd } = useApiUrlStore()

  return (
    <div className="md:flex items-center hidden space-x-3">
      <GlobalLoader />
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text px-2">{isProd ? 'Server' : 'Debug'}</span>
          <input
            type="checkbox"
            className="toggle"
            checked={isProd}
            onChange={toggleApiUrl}
          />
        </label>
      </div>
      <ConnectKitButton />
    </div>
  )
}

type MobileMenuButtonProps = {
  onClick: VoidFunction
}

function MobileMenuButton({ onClick }: MobileMenuButtonProps) {
  return (
    <div className="md:hidden flex items-center">
      <button className="mobile-menu-button outline-none" onClick={onClick}>
        <svg
          className=" hover:text-primary text-accent w-6 h-6"
          x-show="!showMenu"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>
  )
}
type MobileMenuProps = {
  isOpen: boolean
}

function MobileMenu({ isOpen }: MobileMenuProps) {
  return (
    <div className={isOpen ? 'mobile-menu' : 'hidden mobile-menu'}>
      <ul>{navItems(true)}</ul>
    </div>
  )
}
type NavContainerProps = {
  children: ReactNode
  isMenuOpen: boolean
}

function NavContainer({ children, isMenuOpen }: NavContainerProps) {
  return (
    <nav className="bg-base-300 shadow-lg">
      <div className="max-w-6xl px-4 mx-auto">
        <div className="flex justify-between">{children}</div>
      </div>

      <MobileMenu isOpen={isMenuOpen} />
    </nav>
  )
}
export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  return (
    <NavContainer isMenuOpen={isMenuOpen}>
      <PrimaryNavbar />
      <SecondaryNavbar />
      <MobileMenuButton onClick={() => setIsMenuOpen(!isMenuOpen)} />
    </NavContainer>
  )
}
