import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import '../styles/app.css'
import { NavBar } from '../components/mollecules'

const LayoutMain = (): JSX.Element => {
  return (
    <>
      <NavBar></NavBar>
      <main className="layout mt-6">
        <div className="container mx-auto">
          <Outlet />
        </div>
      </main>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default LayoutMain
