import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import Plot from 'react-plotly.js'

import useTheme from '../../hooks/useTheme'

interface IRektToolInput {
  fundingFeeRate: number
  feePerTx: number
  averageTradeTime: number
}

const RektFees = () => {
  const { register, handleSubmit } = useForm<IRektToolInput>()
  const [amountPerPosition, setAmountPerPosition] = useState<number[]>([])
  const [z, setZ] = useState<number[][]>([])
  const [fundingFeesRate, setFundingFeesRate] = useState<number>(0.0051)
  const [feePerTx, setFeePerTx] = useState<number>(0.13)
  const [averageTradeTime, setAverageTradeTime] = useState<number>(960)
  const { themeProperty } = useTheme()
  const theme = themeProperty()
  const onSubmit: SubmitHandler<IRektToolInput> = async (form) => {
    setFundingFeesRate(form.fundingFeeRate)
    setFeePerTx(form.feePerTx)
    setAverageTradeTime(form.averageTradeTime)
  }

  useEffect(() => {
    updateAmountPerPosition()
    updateAxis()
  }, [fundingFeesRate, feePerTx, averageTradeTime])

  function updateAmountPerPosition() {
    const array: number[] = []
    for (let i = 300; i < 3500; i += 10) {
      array.push(i)
    }
    setAmountPerPosition(array)
  }

  function updateAxis() {
    const tempZ: number[][] = []
    for (let i = 0; i < amountPerPosition.length; i++) {
      const feePerPosition = getFeesForPosition(amountPerPosition[i])
      const zAxis: number[] = []

      for (let t = 1; t < 1000; t++) {
        const totalFees = feePerPosition * t
        zAxis.push((totalFees * 100) / amountPerPosition[i])
      }

      tempZ.push(zAxis)
    }
    setZ(tempZ)
  }

  function getFeesForPosition(amountPerPosition: number): number {
    const tradeTime = averageTradeTime / 60
    const fundingFees = amountPerPosition * tradeTime * (fundingFeesRate / 100)
    const positionFees = amountPerPosition * 0.0001
    const txFees = feePerTx * 2 // Open + SL or TP

    return fundingFees + txFees + positionFees
  }

  return (
    <div className="container mx-auto">
      <form
        className=" form-control grid content-center grid-cols-3 gap-4 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className="input-group">
          <span>Funding fees rate</span>
          <input
            type="text"
            placeholder="0.000051"
            className="text-center"
            {...register('fundingFeeRate')}
          />
          <span>%</span>
        </label>
        <label className="input-group">
          <span>Fee per tx</span>
          <input
            type="text"
            placeholder="0.13"
            className="text-center"
            {...register('feePerTx')}
          />
          <span>$</span>
        </label>
        <label className="input-group">
          <span>Average trade duration</span>
          <input
            type="text"
            placeholder="960"
            className="text-center"
            {...register('averageTradeTime')}
          />
          <span>Minutes</span>
        </label>
        <button type="submit" className="btn">
          Run
        </button>
      </form>

      <Plot
        data={[
          {
            colorscale: 'Portland',
            marker: { color: theme.accent },
            type: 'surface',
            y: amountPerPosition,
            z: z,
          },
        ]}
        layout={{
          height: 800,
          margin: {
            b: 20,
            l: 0,
            pad: 0,
            r: 0,
            t: 0,
          },
          paper_bgcolor: '#121212',
          plot_bgcolor: theme.secondary,
          scene: {
            xaxis: {
              title: 'Trade Count',
              titlefont: {
                color: theme.primary,
                size: 12,
              },
            },
            yaxis: {
              title: 'Amount per trades',
              titlefont: {
                color: theme.primary,
                size: 12,
              },
            },
            zaxis: {
              title: '%fees per amont per trade',
              titlefont: {
                color: theme.primary,
                size: 12,
              },
            },
          },
          width: 1100,
        }}
      />
    </div>
  )
}
export default RektFees
