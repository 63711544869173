import type { IWorkflowStore } from '../workflowStore'

export const WorkflowSelector = (state: IWorkflowStore) => ({
  edges: state.edges,
  initWorkFlow: state.initWorkFlow,
  nodes: state.nodes,
  onConnect: state.onConnect,
  onEdgesChange: state.onEdgesChange,
  onNodesChange: state.onNodesChange,
  resetWorkflow: state.resetWorkflow,
  setNodes: state.setNodes,
  updateNodeData: state.updateNodeData,
})
